import {React} from 'react';
import Styles from '../styles/blockquote.module.scss';

import {motion} from 'framer-motion';

export default function Blockquote({id,colour, children}) {
    return (
        <div className={Styles.blockQuote} style={{backgroundColor:colour}} id={id}>
            {children}
        </div>
    )
}