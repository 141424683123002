import {React} from 'react';
import Styles from '../styles/cover.module.scss';

import {motion} from 'framer-motion';

export default function CoverImage({image, id,textColour,children}) {
    return (
        <div 
            className={`${Styles.cover}`} 
            id={id}>
            <div className={`${Styles.content}`} style={{ color:textColour}}>
                {children}
            </div>
            <img src={image} alt="packed lunch" />
        </div>
    )
}