import './App.scss';
import Landing from './sections/Landing';
import Blockquote from './sections/Blockquote';
import DualCol from './sections/DualCol';
import CoverImage from './sections/CoverImage';
import MenuIcon from './components/menuIcon';
import { ReactComponent as IG } from './images/instagram.svg';
import { ReactComponent as Mail } from './images/mail.svg';
function App() {

    const bg = {
        initial: { opacity: 0, },
        animate: {
            opacity: 1, transition: {
                duration: 1,
                staggerChildren: 0.3,
                delayChildren: 0.5
            }
        }
    }

    const text = {
        initial: { opacity: 0, y: -40 },
        animate: { opacity: 1, y: 0, transition: { duration: 1 } }
    }

    const para = {
        initial: { opacity: 0, y: -5 },
        animate: { opacity: 1, y: 0, transition: { duration: 1, delay: 2.5 } }
    }

    return (
        <>
            <MenuIcon />
            <Landing />
            <Blockquote

                colour={'var(--yellow   )'}
            >
                <p>Twelve Noon is a creative studio with an appetite for spirited stories. We help food and drink brands start the conversations that matter.</p>
            </Blockquote>
            <DualCol
                headerStyle={'center'}
                paddingTop={50}
                textAlign={'left'}
                img={'/images/packed-lunch.png'}
                alt={'Return from the shops'}
                textColour={'var(--red)'}
                colour={'var(--offWhite)'}
                rowLayout={true}
                header={'About us'}
                ltr={false}
                id={"about"}

            >
                <p>Twelve Noon specialises in crafting stories, strategies, films and publications for food and drink brands.
                    <br /><br />Whether it’s creating or evolving your tone of voice, making a print magazine from scratch, bringing online journals to life or commissioning a series of short films,
                    our approach helps our clients authentically occupy the space between editorial and advertising.</p>
            </DualCol>
            <Blockquote

                colour={'var(--red)'}
            >
                <p>We don’t just work in the food and drinks industries, we are part of them. This puts us ahead of the curve and gives us a unique perspective.</p>
            </Blockquote>

            <DualCol
                headerStyle={'center'}
                paddingTop={0}
                imageHeight={43}
                imageWidth={114}
                img={'/images/cover-option.png'}
                alt={'Return from the shops'}
                textColour={'var(--green)'}
                colour={'var(--offWhite)'}
                ltr={false}
                rowLayout={true}
                header={`We are a tale of <br /> two halves`}


            >
                <p>Twelve Noon was founded by independent publishers Miranda York (At The Table) and Aimee Hartley (Above Sea Level). With backgrounds in media, publishing and marketing, we approach every project with curiosity, care and journalistic rigour.
                    <br /><br />
                    Having built our own brands, and helped to shape other people’s too, our collective experiences have given us a unique point of view on the industries we are proud to call our own.</p>
            </DualCol>

            <Blockquote
                id={'work'}
                colour={'var(--green)'}
            >
                <h2>Client overview:</h2>
                <p>Daylesford Organic, Maybourne Hotel Group, Hakkasan, Seedlip, Spotify, Rosewood London, Wine Institute of California, Gail's Bakery, Hyundai, Newcomer Wines, Borough Market, Action Against Hunger, Shackleton Whisky, Wholefoods, Unlisted Collection, Argent, The Dorchester, Nyetimber</p>
            </Blockquote>

            <DualCol
                img={'/images/crab.png'}
                headerOverImage={true}
                paddingTop={30}
                rowLayout={false}
                textColour={'var(--red)'}
                colour={'var(--offWhite)'}
                ltr={false}
                id={`services`}
                header={'Our Services'}
            >
                <ul>
                    <li>
                        <h4>Tone of Voice</h4>
                        A brand language that lifts your values right off of the page.
                    </li>
                    <li>
                        <h4>Creative Copywriting</h4>
                        Weaving your tone of voice through every aspect of your brand.
                    </li>
                    <li>
                        <h4>Print Projects</h4>
                        End-to-end creation of magazines, newspapers and books.
                    </li>
                    <li>
                        <h4>Digital Editorial</h4>
                        Concept development and commissioning for journals or bespoke platforms.
                    </li>
                    <li>
                        <h4>Film</h4>
                        Creative development and production.
                    </li>
                    <li>
                        <h4>Curation &amp; Placemaking</h4>
                        Building communities and creating a sense of place through food and drink.
                    </li>
                </ul>
            </DualCol>
            <CoverImage
                textColour={'var(--blue)'}
                image={`/images/blue-tablecloth.jpg`}
                id={`contact`}
            >
                <h2>Let's work together</h2>
                <div>
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/_twelvenoon_/"><IG style={{ height: '25px' }} />__twelvenoon__</a>
                    <a href="mailto:info@twelvenoon.co" target="_top"><Mail style={{ padding: '0 20px' }} />info@twelvenoon.co</a>
                </div>
            </CoverImage>
        </>
    );
}

export default App;
