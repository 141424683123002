import { React, useState } from 'react';
import Styles from '../styles/menuicon.module.scss';

import { motion } from 'framer-motion';

export default function MenuIcon() {

    const [isActive, setActive] = useState(false);
    const list = {
        closed: { opacity: 0 },
        open: {
            opacity: 1,
            transition: {
                delay: 0.3,
                duration: 0.3,
            }
        }
    }

    const item = {
        closed: { opacity: 0 },
        open: {
            opacity: 1,
            transition: {
                duration: 0.4,
            }
        }
    }

    const toggle = () => setActive(!isActive);

    const closeMenu = () => {

    }

    return (
        <>
            <div className={`${Styles.pip} ${isActive ? Styles.active : ''}`} onClick={toggle}>
            </div>
            <motion.div className={`${Styles.menu} ${isActive ? Styles.active : ''}`} variants={list} initial="initial" animate={isActive ? "open" : "closed"}>
                <div className={Styles.cross} onClick={toggle}>X</div>
                <ul>
                    <li>
                        <a onClick={toggle} href="/#about">About</a>
                    </li>
                    <li>
                        <a onClick={toggle} href="/#services">Services</a>
                    </li>
                    <li>
                        <a onClick={toggle} href="/#work">Work</a>
                    </li>
                    <li>
                        <a onClick={toggle} href="/#contact">Contact</a>
                    </li>
                </ul>
            </motion.div>
        </>
    )
}