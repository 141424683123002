import { React } from 'react';
import Styles from '../styles/dualcol.module.scss';

import { motion } from 'framer-motion';

export default function DualCol(props) {
    return (
        <div
            className={`${Styles.dualCol} ${props.ltr ? Styles.ltr : ''} ${props.rowLayout ? Styles.rowLayout : ''} ${props.headerStyle ? Styles.headerCenter : ''}`}
            id={props.id}
            style={{
                backgroundColor: props.colour,
                padding: `${props.paddingTop}px 0`
            }}
        >
            <div className={`${Styles.column} ${Styles.imgCol} ${props.headerOverImage ? Styles.headerOverImage : ''}`}  style={{ color: props.textColour}}>
                {props.headerOverImage ? <h3 style={{textAlign:props.headerStyle}} dangerouslySetInnerHTML={{__html: props.header}}></h3> : null}
                <img src={props.img} alt={props.alt} />
            </div>
            {props.rowLayout ? 
            <div className={`${Styles.row}`} style={{ backgroundColor: props.colour, color: props.textColour, textAlign: props.textAlign }}>
                <div className={Styles.rowCol}>
                   <h3 style={{textAlign:props.headerStyle}} dangerouslySetInnerHTML={{__html: props.header}}></h3>
                </div>
                <div className={Styles.rowCol}>
                    {props.children}
                </div>
            </div> : null}
            {!props.rowLayout ? 
            <div className={`${Styles.column} `} style={{ backgroundColor: props.colour, color: props.textColour, textAlign: props.textAlign }}>
               {!props.headerOverImage ? <h3 style={{textAlign:props.headerStyle}} dangerouslySetInnerHTML={{__html: props.header}}></h3> : null}
                {props.children}
            </div>
            : null}
        </div>
    )
}