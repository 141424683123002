import {React} from 'react';
import Styles from '../styles/landing.module.scss';
import twelve from '../images/twelve.svg';
import noon from '../images/noon.svg';
import {motion,  useViewportScroll, useTransform} from 'framer-motion';

export default function Landing(props) {
    
    const {scrollY} = useViewportScroll();
    const y = useTransform(scrollY,[0,200],[0,-30],{clamp:false});

    return (
        <div className={Styles.container}> 
            
            <motion.div className={Styles.landingImg} >
                <img src="./images/still-life.jpg" alt="still life" />
            </motion.div>
            <motion.div className={Styles.logo} style={{y}} >
                <img src={twelve} alt="twelve" />
                <img src={noon} alt="noon" />
            </motion.div>
        </div>
    )
}